@font-face {
    font-family: "Biotif";
    src: url("assets/fonts/Biotif-Regular.eot");
    src: url("assets/fonts/Biotif-Regular.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/Biotif-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Biotif-Regular.woff") format("woff"),
    url("assets/fonts/Biotif-Regular.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Biotif";
    src: url("assets/fonts/Biotif-Bold.eot");
    src: url("assets/fonts/Biotif-Bold.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/Biotif-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Biotif-Bold.woff") format("woff"),
    url("assets/fonts/Biotif-Bold.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    height: 100vh;
    font-family: "Biotif"
}
